<template>
    <div>
        <div class="m-2">
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                    <label>Show</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                        @input="filterTable"
                    />
                    <label>entries</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="9" class="mobile_tab_max_width_flex">
                    <div class="d-flex align-items-center justify-content-end mobile-view mr_1_last_child mobile_tab_display_block">
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem"
                            placeholder="Search..."
                            @input="filterTable"
                        />

                        <b-button
                            :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                            variant="success"
                            class="mr-1 mobile-margin-bottom"
                            @click="openPDFPopUp()"
                            v-if="checkPermission($route.name, 'Pdf')"
                        >
                            <span class="text-nowrap">PDF</span>
                        </b-button>

                        <b-button
                            :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                            variant="secondary"
                            class="mr-1 mobile-margin-bottom"
                            @click="download('excel')"
                            v-if="checkPermission($route.name, 'Excel')"
                        >
                            <span class="text-nowrap">Excel</span>
                        </b-button>

                        <b-button
                            :disabled="!(selected.length > 0)"
                            variant="danger"
                            class="mr-1 mobile-margin-bottom"
                            @click="changeMultipleStatus('deleted')"
                            v-if="checkPermission($route.name, 'Delete')"
                        >
                            <span class="text-nowrap">Delete</span>
                        </b-button>

                        <b-button
                            :disabled="siteData != null && siteData._id != 'all-site'  ? false:true"
                            variant="primary"
                            class="mr-1 mobile-margin-bottom"
                            @click="zipDownload()"
                            v-if="checkPermission($route.name, 'Zip Download')"
                        >
                            <span class="text-nowrap">Zip Download</span>
                        </b-button>

                    </div>
                </b-col>

            </b-row>
        </div>
        <b-table sticky-header
            ref="refUserListTable"
            class="position-relative mTable"
            :items="dataList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
        >
            <!-- Column: User -->
            <template #head(checkbox)="items">
                <b-form-checkbox class="custom-control-warning p-0" v-model="selectAll" @change="selectall"/>
            </template>

            <template #cell(checkbox)="items">				
                <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />				
            </template>				

            <template #cell(info)="items">                
                <feather-icon :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon' " @click="items.toggleDetails" />
            </template>

            <template #cell(unique_id)="items">	
                <div class="w-100" @click="items.toggleDetails">
                    <span class="wordBreak">{{ items.item.unique_id}}</span>				
                </div>			
            </template>

            <template #cell(title)="items">				
                <span @click="items.toggleDetails" class="wordBreak">{{ items.item.title | capitalize}}</span>				
            </template>          

            <template #cell(created_at)="items">
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,date_format,time_format)}}</span>
            </template>

            <template #cell(actual_started)="items">
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime2(items.item.actual_started,date_format,time_format)}}</span>
            </template>

            <template #cell(actual_end)="items">
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime2(items.item.actual_end,date_format,time_format)}}</span>
            </template>

            

            <!-- <template #cell(task_status)="items">
                <b-badge pill :variant="variantProgressColor(items.item.task_status)" class="text-capitalize mb-1 mt-1">
                    {{ items.item.task_status | capitalize}}
                </b-badge>				
            </template> -->

            

            <template #cell(actions)="items">
                <div>
                    <b-link v-if="(checkPermission($route.name, 'Download Report')) && (items.item.task_status == 'closed' && items.item.pdf != null)" @click="openPdf(items.item.pdf)" v-b-tooltip.hover.v-warning title="Download Report" variant="outline-warning">
                        <feather-icon icon="DownloadIcon" class="mediumSize mr-1" />	
                    </b-link>

                    <b-link v-if="(checkPermission($route.name, 'Re Generate Report')) && (items.item.task_status == 'closed')" @click="reGenerateReport(items.item._id)" v-b-tooltip.hover.v-warning title="Re Generate Report" variant="outline-warning">
                        <feather-icon icon="RefreshCwIcon" class="mediumSize mr-1" />
                    </b-link>

                    <!-- <b-button size="sm" @click="items.toggleDetails" class="btn btn-warning ml-1 smallFonts">
                        {{ items.detailsShowing ? 'Hide' : 'Show'}} Add Info
                    </b-button> -->
                </div>
            </template>

            <template #row-details="items">
                <div class="p-1 px-2 fbDetail mb-1">
                    <b-row>

                        <!-- <b-col cols="12" xl="12">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Project Site 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Location 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Feedback From 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.feedback_from == 'web'">QR Code</span>
                                        <span class="wordBreak" v-else-if="items.item.feedback_from == 'app'">Feedback Panel</span>
                                        <span class="wordBreak" v-else>{{ 'N/A'  }}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Trigger Time 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,date_format,time_format)}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Actual Time 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData" class="wordBreak">{{sitedateTime2(items.item.actual_started,date_format,time_format)}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Completed Time 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData" class="wordBreak">{{sitedateTime2(items.item.actual_end,date_format,time_format)}}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Crew 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.crew | crewArray }}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Completed By 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Time Taken 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>


                                    

                                </tr>
                            </table>
                        </b-col> -->

                        <b-col cols="12" xl="12">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Project Site 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Location 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Feedback From 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.feedback_from == 'web'">QR Code</span>
                                        <span class="wordBreak" v-else-if="items.item.feedback_from == 'app'">Feedback Panel</span>
                                        <span class="wordBreak" v-else>{{ 'Web'  }}</span>
                                    </td>
                                </tr>

                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.start,date_format,'' ) }}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            End Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.end,date_format,'' ) }}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Crew 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.crew | crewArray }}</span>
                                    </td>

                                    

                                    
                                </tr>
                                <tr class="mb-1">
                                    

                                    
                                    

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Completed By 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Time Taken 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%" v-if="items.item.site.additional_information == 'yes'">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Comment 
                                          </h6>
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;" v-if="items.item.site.additional_information == 'yes'">
                                      <span class="wordBreak" v-if="items.item.comment && items.item.comment.length > 0">{{ items.item.comment | capitalize }}</span>
                                      <span v-else>N/A</span>
                                    </td>
                                </tr>

                                <tr class="mb-1" v-if="items.item.site.additional_information == 'yes'">
                                  <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Images 
                                          </h6>
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;" v-if="items.item.task_feedback_images.length == 0">
                                        <span class="wordBreak">N/A</span>
                                    </td>
                                </tr>
                                <tr class="mb-1" v-if="items.item.site.additional_information == 'yes' && items.item.task_feedback_images.length > 0">
                                  <th class="pb-50" colspan="6">
                                      <div class="d-flex align-items-center" >
                                        
                                        <div class="" style="display: flex; flex-wrap: wrap;">
                                          <div v-for="(image, index) in items.item.task_feedback_images" :key="index" class="px-1 pb-1">
                                            <img @click="openImage(image.image)" :key="items.index +''+ Math.random()" :src="image.image" alt="Preview" class="rounded" style="width: 100px; height: 100px; object-fit: cover;"> 
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </th>
                                </tr>
                                
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                        
                    </b-row>
                </div>

            </template>

        </b-table>

        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end pagination_fix">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>
                    </b-pagination>
                </b-col>

            </b-row>
        </div>

        <b-modal
            id="pdf_export"
            ref="pdf_export"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Generate PDF"
            @ok="validatePDF"
            no-close-on-backdrop
        >
            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}}</div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <div class="demo-inline-spacing">
                            <b-form-radio class="mb-1" name="some-radios" value="single" v-model="pdf_type"> Single Page </b-form-radio>
                            <b-form-radio class="mb-1" name="some-radios" value="multiple" v-model="pdf_type"> Multiple Pages </b-form-radio>
                        </div>
                    </b-col>
                </b-row>
            </b-form>

        </b-modal>

        <b-modal id="image-modal" ref="image-modal" centered title="Feedback Image" hide-footer :no-close-on-backdrop="false">
      
          <template>
            <center>
              <div id="pluswrap" v-if="isLoadingImg">
                <div class="plus">
                  <b-spinner style="width: 3rem; height: 3rem;" class="mr-1" label="Large Spinner" variant="warning" />
                </div>
              </div>
              <img v-show="isLoaded" :src="img" alt="Feedback Image" style="max-width: 460px;height: auto" @load="isLoadingImg = false; isLoaded = true">
            </center>
          </template>
      
        </b-modal>

    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, 
  BDropdownItem, BPagination, BCardBody, BFormCheckbox, BAlert, BForm, BBreadcrumb, BFormDatepicker,
  BTabs, BTab, BFormSelect, BFormSelectOption, VBTooltip, BFormRadio, BImgLazy, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import Completed from './Completed.vue';
import Ripple from 'vue-ripple-directive'

export default {
	components: {
		BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown,
		BDropdownItem, BPagination, BFormCheckbox, vSelect, BAlert, BForm, BBreadcrumb, BFormDatepicker,
		BTabs, BTab, BFormSelect, BFormSelectOption, Completed, BFormRadio, BImgLazy, BSpinner
	},
	directives: {
		'b-tooltip': VBTooltip, Ripple
	},
    props: ['siteData', 'locations', 'crew_filter', 'status', 'start', 'end', 'assign_status'],
    watch: { 
        siteData: function(newVal, oldVal) { // watch it
            this.filterTable();
            this.updateDateFormat();
        },
        locations: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        crew_filter: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        status: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        start: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        end: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        assign_status: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
    },

	data() {
		return {
            date_format : 'DD MMM YYYY',
			time_format : 'HH:mm',
		    tableColumns: [
                    { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                    { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '3%',fontSize:'11px'}},
                    { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '16%',fontSize:'11px'}},
                    
                    { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '22%',fontSize:'11px'}},
                    
                    { key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                    { key: 'actual_started', label: 'Start Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                    { key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},
                    
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '14%',fontSize:'11px'}}

                ],

			items:[],
			isSortDirDesc: true,
			perPageOptions: [10, 20, 50, 100],
			perPage : 10,
			sortBy : 'updated_at',
			webUrl: process.env.VUE_APP_SERVER_URL,
			totalRecords : 0,
			currentPage : 1,
			searchQuery:'',
			from:null,
			to:null,
			selectAll:false,
			selected:[],
			crew:[],
			yearData:[],
			sites:[],
			popshowDismissibleAlert:false,
			error_message:'',
      pdf_type:'single',

      img:null,
      isLoadingImg:true,
      isLoaded:false
		}
	},

	methods : {

    openImage(img){
      if (img && img != 'null' && img != '') {
        this.isLoadingImg = true;
        this.isLoaded = false;
        this.img = img+'?'+ new Date();
        this.$refs['image-modal'].show();
      }

    },

        updateDateFormat(){
			if (this.siteData != '') {
				this.sites.forEach(item => {
                    if (item._id == this.siteData) {
                        this.date_format = item.pdf_date_format;
                        this.time_format = item.pdf_time_format; 
                    }
				})
			}else{
				this.date_format = 'DD MMM YYYY';
				this.time_format = 'HH:mm';
			}
		},

		dataList(){
			return this.$store.dispatch(POST_API, {
				data:{
					page   	      : this.currentPage,
					keyword	      : this.searchQuery,
					rowsPerPage   : this.perPage,
					sortBy 	      : this.sortBy,
					sortDirection : this.isSortDirDesc,
					role   	      : this.$store.getters.currentUser.role,
					om_sites      : this.$store.getters.currentUser.om_sites,
					site   	      : this.siteData ? this.siteData._id : null,
					locations     : this.locations,
					crew   	      : this.crew_filter,
					start	      : moment(this.start).format('YYYY-MM-DD'),
					end    	      : moment(this.end).format('YYYY-MM-DD'),
					status 	      : this.status,
					tab_status    : 'closed',
                    assign_status : this.assign_status,
				},
				api: '/api/feedback-request-status-list'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					this.items        = this.$store.getters.getResults.data.docs;
					this.totalRecords = this.$store.getters.getResults.data.total;
					this.perPage      = this.$store.getters.getResults.data.limit;
					this.from         = this.$store.getters.getResults.data.from;
					this.to           = this.$store.getters.getResults.data.to;

          var data = {
              open : this.$store.getters.getResults.data.open,
              in_progress : this.$store.getters.getResults.data.in_progress,
              validate : this.$store.getters.getResults.data.validate,
              failed : this.$store.getters.getResults.data.failed
          }

          this.$emit('updateCount',data);
                    
					return this.items;
				}
			});
		},

		filterTable(){
            this.$refs.refUserListTable.refresh();
			this.selected = [];
			this.selectAll = false;
		},

		variantColor(status){
			if (status == 'active') {
				return 'success';
			}else{
				return 'primary';
			}
		},

		variantProgressColor(status){
			if (status == 'open') {
				return 'primary';
			}else if(status == 'in_progress'){
				return 'warning'
			}else if(status == 'validate'){
				return 'info'
			}else if(status == 'failed'){
				return 'danger'
			}else{
				return 'success';
			}
		},

        selectall(e){
			this.selected = [];		
			if (e) {
				var selected = [];

				this.items.forEach(function(item){
					selected.push(item._id);
				})
				this.selected = selected;
			}
		},

        changeMultipleStatus(status){
            if (this.selected.length > 0) {
                var msg = '';
                var msg2 = '';

                if (status == 'deleted') {
                    msg = 'Are you sure you want to delete selected record?';
                    msg2 = 'Records Deleted Successfully.';
                }if (status == 'active') {
                    msg = 'Are you sure you want to Activate selected record?';
                    msg2 = 'Records Activated Successfully.';
                }if (status == 'inactive') {

                    msg = 'Are you sure you want to inactive selected record?';
                    msg2 = 'Records inactivated Successfully.';
                }   

                Swal.fire({
                    title: 'Please Confirm',
                    text: msg,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id: this.selected,
                                status:status
                                },
                            api:"/api/change-multiple-feedback-request-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                Swal.fire({
                                    icon: 'success',
                                    title: msg2,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.selected = [];
                                this.selectAll = false;
                                this.filterTable();
                            }
                        });
                    }
                }).catch(err => {})
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Please select tasks.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },

        getZip(){
            var organization  = this.$store.getters.currentUser.organization;
            var keyword       = this.searchQuery;
            var sortBy        = this.sortBy;
            var sortDirection = this.isSortDirDesc;
            var site          = this.siteData ? this.siteData._id : null;
            var locations     = this.locations.length > 0 ? JSON.stringify(this.locations) : [];
            var crew          = this.supervisor && this.supervisor.length > 0 ? this.supervisor : [];
            var start         = moment(this.start).format('YYYY-MM-DD');
            var end           = moment(this.end).format('YYYY-MM-DD');
            var report        = 'service';
            var role          = this.$store.getters.currentUser.role;
            var om_sites      = this.$store.getters.currentUser.om_sites;
            var assign_status = this.assign_status

            var api    = '/api/zip-feedback-request?';
            var params = "organization="+organization+"&keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+
                            "&site="+site+"&locations="+locations+"&crew="+crew+"&start="+start+"&end="+end+
                            "&report="+report+"&role="+role+"&om_sites="+om_sites+"&assign_status="+this.assign_status+"&user_id="+this.$store.getters.currentUser._id;

            var baseurl = "";
            baseurl = process.env.VUE_APP_SERVER_URL + api + params;

            window.open(baseurl,'_blank');
        },

        zipDownload(){
            return this.$store.dispatch(POST_API, {
                data:{
                    keyword: this.searchQuery,                 
                    sortBy:this.sortBy,
                    sortDirection:this.isSortDirDesc,
                    site:this.siteData ? this.siteData._id : null,
                    locations : this.locations,
                    supervisor: this.supervisor,
                    start:moment(this.start).format('YYYY-MM-DD'),
                    end:moment(this.end).format('YYYY-MM-DD'),
                    report:'service',
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                    assign_status:this.assign_status
                },
                api:"/api/check-zip-feedback-request",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.errorAlert();
                } else {
                    var data = this.$store.getters.getResults.data;
                    
                    if(data > 0){
                      this.getZip();
                    } else {
                      this.errorAlert();
                    }
                    // if (data) {
                    //     var link = document.createElement("a");
                    //     link.download = data.name;
                    //     link.href = data.file;
                    //     document.body.appendChild(link);
                    //     link.click();
                    //     document.body.removeChild(link);
                    // }
                }
            });
        },

        openPdf(path){
            window.open(path+'?'+new Date(), '_blank');
        },

        reGenerateReport(id){
            return this.$store.dispatch(POST_API, {
                data:{ id:id },
                api: '/api/regenerate-feedback-request-report'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.popshowDismissibleAlert = true;
                } else {
                    this.popshowDismissibleAlert = false;
                    
                    var data  = this.$store.getters.getResults.data;
                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Report Regenerated Successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then( () => {
                        window.open(data + '?' + new Date(), '_blank');
                        this.filterTable();  
                    })
                }
            });
        },

        openPDFPopUp(){
            if (!this.start || this.start == 'Invalid date') {
              this.customAlert('Select Date', 'Please select date to Download Report.', 'warning')
            }else{
                this.pdf_type = 'single';
                this.$refs['pdf_export'].show();
            }
           
        },

        validatePDF(e){
            this.download('pdf');
            this.$refs['pdf_export'].hide();
        },

        download(type){
          if (!this.start || this.start == 'Invalid date') {
            this.customAlert('Select Date', 'Please select date to Download Report.', 'warning')
          }else{

            
            
            var keyword = this.searchQuery;
            var sortBy = this.sortBy;
            var sortDirection = this.isSortDirDesc;
            var site = this.siteData ? this.siteData._id : "";
            var site_name = this.siteData ? this.siteData.site_name : "";
            
            var locations  =  [];

            for (var i = 0; i < this.locations.length; i++) {
                locations.push(this.locations[i]._id);
            }

            var om_sites = [];

            for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
                om_sites.push(this.$store.getters.currentUser.om_sites[i]);
            }

            var role = this.$store.getters.currentUser.role;

            var crew =  this.crew;
            
            console.log(this.start,'+++++');

            var start = this.start != null && this.start != '' ? moment(this.start).format('YYYY-MM-DD') : "";
            var end = this.end != null && this.end != '' ? moment(this.end).format('YYYY-MM-DD') : "";
            var status = 'closed' ;
            var assign_status = this.assign_status ;

            var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&site_name="+site_name+
            "&locations="+locations+"&crew="+crew+"&start="+start+"&end="+end+"&status="+status+"&organization="+ this.$store.getters.currentUser.organization+
            "&role="+role+"&om_sites="+om_sites.join(',')+"&pdf_type="+this.pdf_type+"&assign_status="+this.assign_status+"&user_id="+this.$store.getters.currentUser._id;
            
            var baseurl = "";

            if (type == 'pdf') {
                baseurl = process.env.VUE_APP_SERVER_URL +"/api/feedback-request-status-pdf?"+params;
            }else{
                baseurl = process.env.VUE_APP_SERVER_URL +"/api/feedback-request-status-excel?"+params;
            }
            window.open(baseurl,'_blank');
          }
        },

	},

    mounted(){
        // this.dataList();
        console.log(this.start);
    }
  
}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
	.wordBreak2{
		text-align: justify !important;
		text-justify: inter-word !important;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>